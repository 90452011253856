div{
    color:white;
    font-size: 14pt;
}

a:link{
    text-decoration: underline;

}

.word-defining {
    color:blue;
    text-decoration: underline;
}

.word-voting {
    color: white;
}

.vote-now {
    color:white;
    text-decoration: underline;
}

#tblWordBounty{
    width:1000px;
    color:white;
    border-bottom:1px solid #727272
}

.header{
    font-size: 16pt;
    color:darkgray;
}

table{
    border:0px solid #727272;
    border-right:0px;
    border-left:0px;
    margin-top:20px;
    width:1000px;
    color:white;
}
table > thead > tr > th{
    border-bottom:1px solid #727272;
    border-top:1px solid #727272;
    font-size: 14pt;
    color:gray;
    width:20%;
}
table > tbody > tr > td{
    border-bottom:1px solid #727272; /*formerly #656565*/
    font-size: 12pt;
    border-right:0px;
    border-left:0px;
}
