@font-face {
    font-family: 'Trocadero';
    src: url('../../fonts/trocadero.regular.ttf')  format('truetype') 
  } 

  
.word-bounty{
    font-family: "Trocadero","Comic Sans MS", "Comic Sans"/*, cursive*/;
    font-style: normal;
    color: lightgoldenrodyellow;
    font-size: 36pt;
    /*font-style: italic;*/
}

.slinger{
    font-family: "Comic Sans MS", "Comic Sans", cursive;
    color: yellow;
    font-size: 16pt;
    font-style: italic;
}

.wordBountyCopy{
    color:#f8f8ff;
    font-style: italic;
}

a:link {
    color: lightgoldenrodyellow;
    text-decoration: underline;
  }
  
  /* visited link */
  a:visited {
    color: lightgoldenrodyellow;
  }
  
  /* mouse over link */
  a:hover {
    color: #125699;
  }
  
  /* selected link */
  a:active {
    color: lightgoldenrodyellow;
  } 

 