img{
    max-width:90%;
    max-height:100%;
    border-radius:15px;
}

.carousel-item.active > img {
    border:0.5px solid gray;

}
.carousel-item.active {
    display: block;
    width:100%;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 150px;
    margin-left: 80px;
}
 

.carousel-inner  {

    display: block;
    width:500px;
    margin: auto;
} 

.carousel.slide{
    margin:auto;
    width:500px;
    width:500px;
}  

.carousel-indicators {
    top:100%;
    
}

.carousel-indicators li { visibility: hidden; }
