*{
    margin: 0;
    padding: 0;
  }
  


  .heading{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 1rem;
    font-weight: bold;
  
  }

  
  #input[type="file"] {
      display: none;
  }

  label{
    font-size:16pt;
    position:relative;
    left:300px;
    text-align: right;
}

#lblUsage{
    right:-542px;
}
#lblDefinition{
    right:-630px;
}
#lblPartOfSpeech {
    right: -228px;
}
#lblRedPartOfSpeech{
    right:-220px;
}
#lblRedDefinition{
    right:-620px;
}
#lblRedUsage{
    right:-535px;
}
.chklabel{
    display: flex;
    align-items: center;
    font-size:12pt;
    padding-right: 295px;
    text-indent: 15px;
}
/* 
  .label{
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  } */

  .image-upload{
    margin: auto;
    width: 200px;
      height: 50px;
    color: white;
    border-radius: 10px;
    background-color: black;
    text-align: center;
    cursor: pointer;
  }

  /* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v55/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
  }
  
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
  }

  .pointer{
    cursor:pointer;
}
