h4 {
    font-size: 13pt;
    padding-bottom: 3px;
    color:white;
    padding-left: 25px;
    line-height: 25px;
}

h3{
    font-size: 18pt;
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 50px
}

.quote{
    font-style: italic;
}