.howItWorksText{
   font-size: large; 
   color: white;
   padding: 10px;
}
.howItWorksTitle{
   color:yellow;
}
h3 {
   color:white;
}