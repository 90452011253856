#localStorage{
    color:lightgoldenrodyellow;
}

.flex-container {
    display: flex;
    justify-content: center;
    background-color: black;
    width: 200px;
  }

  .button-container {
    display: flex;
    justify-content: center;
    
    padding: 10px;
    width: 100%;
  }

  button {
    margin: 10px; 
    size:10px;
    border-radius:30px;
    color:"white";
    background-color: "blue"; 
  }
  
  .flex-container > div {
    background-color: black;
    margin: 100px;
    padding: 20px;
    font-size: 30px;
    border-radius: 10px;
    border-color: white;
    border-style: solid;
    width: 500px;

  }